declare const window: any;
export const environment = {
  production: window.__env.PRODUCTION,
  msalConfig: {
    auth: {
      clientId: window.__env.CLIENT_ID,
      authority: 'https://login.microsoftonline.com/df7fa6cf-2f69-491d-bacd-8caa90484c7b'
    }
  },
  apiConfig: {
    scopes: [''],
    uri: 'https://login.microsoftonline.com/v1.0/me'
  },
  apiUrl: window.__env.API_URL
};
