import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthContextService {


  public isAuth: EventEmitter<Boolean> = new EventEmitter;

  public token: string;

  constructor() { }

  public isTokenEmpty(){
    return this.token == null || this.token.length == 0;
    }
}
