import {Component, OnInit} from '@angular/core';
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatIcon} from "@angular/material/icon";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {Router} from "@angular/router";
import {AuthContextService} from "../service/auth-context.service";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    NgIf
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})
export class HeaderComponent implements OnInit{

  constructor(
    private authService: MsalService,
    private router: Router,
    private authContext: AuthContextService
  ) {
  }

  fullName: String;
  username: String;

  logout() {
      this.authService.logoutRedirect();
      this.authContext.isAuth.emit(false);
      this.router.navigate(['login']);
    }

  ngOnInit(): void {
    this.authContext.isAuth.subscribe(result => {
      if(result){
        this.refreshUserContext();
      }
    })
  }

  private refreshUserContext() {
      this.fullName = this.authService.instance.getActiveAccount().name;
      this.username = this.authService.instance.getActiveAccount().username;
  }
}
