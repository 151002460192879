import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {NgIf} from "@angular/common";
import {ToastrService} from "ngx-toastr";
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from "@azure/msal-angular";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  InteractionStatus,
  PopupRequest,
  RedirectRequest
} from "@azure/msal-browser";
import {filter, Subject, takeUntil} from "rxjs";
import {AuthContextService} from "../service/auth-context.service";
import {LimitsSettings} from "../dto/limits-settings";
import {LimitsService} from "../service/limits.service";
import {MatIcon} from "@angular/material/icon";
import { saveAs } from 'file-saver';


@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    ReactiveFormsModule, NgbModule, NgIf, MatIcon
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.css'
})
export class MainComponent implements OnInit, OnDestroy {

  constructor(private toastr: ToastrService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private authContext: AuthContextService,
              private limitsService: LimitsService
              ) {
    this.initFormWithValues();
  }

  isIframe = false;
  loginDisplay = false;
  instructionFile = 'How_to_add_slots_and_set_limits.pdf';
  private readonly _destroying$ = new Subject<void>();
  private  limitsSettings = new LimitsSettings();

  form: FormGroup;
  inProgress = false;

  ngOnInit(): void {
    this.checkAndSetActiveAccount();
    this.authService.acquireTokenSilent({scopes: ['openid']})
      .subscribe({
          next: (response: AuthenticationResult) => {
            // Token successfully acquired
            this.setAuthContextData(response.idToken);
            this.checkAndSetActiveAccount();
            this.initForm();
          }, error: error => {
            // If silent token acquisition fails, you may need to prompt the user for interaction
             this.loginRedirect()
          }
        }
      )

    this.authService.handleRedirectObservable().pipe(
      filter(res => res != null)
    ).subscribe({
      next: (res) => {
        this.setAuthContextData(res.idToken);
        let decodedJWT = JSON.parse(window.atob(res.idToken.split('.')[1]));
        this.limitsService.logLogin(decodedJWT.preferred_username, new Date())
      }, error: err => {
        console.log('handleRedirectObservable:', err)
      }
    })

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(result => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.authContext.isAuth.emit(true);
      })
  }


  private setAuthContextData(idToken: string) {
    this.authContext.token = idToken
    this.authContext.isAuth.emit(true);
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  initForm() {
    this.limitsService.getLimitsByUsername(this.authService.instance.getActiveAccount().username)
      .subscribe(    {
        next: (result) => {
          this.limitsSettings = result;
          this.initFormWithValues();
        },
        error: (error) => {
          this.toastr.error("Can't get limit from server")
        }
      })
  }

  private initFormWithValues() {
    this.form = new FormGroup({
      dailyMax: new FormControl(this.limitsSettings.dailyMax,
        [Validators.min(0), Validators.max(10), Validators.pattern("^(\\-)?[0-9]*$")]),
      weeklyMax: new FormControl(this.limitsSettings.weeklyMax,
        [Validators.min(0), Validators.max(20), Validators.pattern("^(\\-)?[0-9]*$")])
    })

    this.form.get('weeklyMax').addValidators(validateOtherField(this.form))

    this.form.get('weeklyMax').valueChanges.subscribe(val =>{
      let dailyValue = this.form.get('dailyMax').value;
      if(val > 0 && dailyValue == 0){
        this.form.get('dailyMax').setValue(null);
      }
    })

    this.form.get('dailyMax').valueChanges.subscribe(val => {
      let weeklyMax = this.form.get('weeklyMax').value;
      if(val == 0 && weeklyMax != null && weeklyMax > 0 ){
        this.form.get('weeklyMax').setValue(0);
      }
      this.form.controls['weeklyMax'].updateValueAndValidity();
    })

    function validateOtherField(form: FormGroup): ValidatorFn {

      return (control: AbstractControl): ValidationErrors | null => {
        const dailyValue = form.get('dailyMax').value;
        const weeklyValue = form.get('weeklyMax').value;

        if (weeklyValue !== null && dailyValue !== null && (parseFloat(weeklyValue) < parseFloat(dailyValue))) {
          return {'invalidValue': true};
        }
        return null;
      }
    }
  }



  submit() {
    this.inProgress = true;
    this.limitsSettings.userName = this.authService.instance.getActiveAccount().username;
    this.limitsSettings.dailyMax = this.form.get("dailyMax").value;
    this.limitsSettings.weeklyMax = this.form.get("weeklyMax").value;
    this.limitsService.saveLimits(this.limitsSettings).subscribe({
      next: (next) => {
        this.toastr.success('Limits have been updated successfully', '');
        this.limitsService.logSettingsUpdate(this.limitsSettings.userName,
          new Date(), this.limitsSettings.dailyMax, this.limitsSettings.weeklyMax )
      }, error: (error) => {
        this.toastr.error('Limits haven\'t been updated', 'Error!');
      }, complete: () => {
        this.inProgress = false;
      }
    });
  }


  checkAndSetActiveAccount(){
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  downloadInstruction() {
    this.limitsService.downloadFileByName(this.instructionFile)
      .subscribe({
        next: (data) => {
          saveAs(data, this.instructionFile);
        },
        error: (error) => {
          this.toastr.error('Error downloading file ' + this.instructionFile);
        }
      })
  }

}
