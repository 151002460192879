<div class="navbar">
  <div class="container">
    <div class="row big">
      <div class="col-2">
        <div class="nav__content">
            <span class="image">
              <img class="col" src="/assets/images/logo.svg" alt="">
            </span>
        </div>
      </div>
      <div *ngIf="fullName" class="col-10 text-end big-menu">
        <span class="login">
              <span class="name">{{fullName}}
                <mat-icon [matMenuTriggerFor]="menu" class="icon pointer">keyboard_arrow_down</mat-icon></span>
             <mat-menu #menu="matMenu">
               <button mat-menu-item (click)="logout()">Log out</button>
             </mat-menu>
           </span>
      </div>
    </div>
    <div class="row small">
      <div *ngIf="fullName" class="col-10 text-end big-menu">
        <span class="login">
              <span class="name">{{fullName}}
                <mat-icon [matMenuTriggerFor]="menu" class="icon pointer">keyboard_arrow_down</mat-icon></span>
             <mat-menu #menu="matMenu">
               <button mat-menu-item (click)="logout()">Log out</button>
             </mat-menu>
           </span>
      </div>
    </div>
  </div>
</div>
