<div class="content">
  <div class="container">
    <div class="row column-min-wh-resize">
      <p class="h5">Welcome!</p>
      <p>Here you can set up interview process limitations to make it more convenient. </p>
      <div class="col-md-6 flex-wrap column-min-wh-resize">
        <form [formGroup]="form" (ngSubmit)="submit()">
          <div class="row">

            <div class="col-md-8">
              <div class="form-group">
                <label for="max-per-day" class="control-label">Maximum per day:</label>
                <input type="number" class="form-control text-lg-end col-3" formControlName="dailyMax" id="max-per-day"
                       placeholder="Enter a number" [min]="0" [max]="10">

                <div *ngIf="form.get('dailyMax').invalid && (form.get('dailyMax').dirty || form.get('dailyMax').touched)">
                  <div *ngIf="form.get('dailyMax').errors['pattern']" class="input-error">Only integer numbers are allowed</div>
                  <div *ngIf="form.get('dailyMax').errors['min'] || form.get('dailyMax').errors['max']"
                       class="input-error">Only numbers between 0 and 10 allowed</div>
                </div>
                <div class="text-muted field-hint">
                  0 not assign interview. Leave empty for no limits. Max is 10
                </div>
              </div>
            </div>

           <div class="col-md-8">
             <div class="form-group">
               <label for="max-per-week" class="control-label">Maximum per week:</label>
               <input type="number" class="form-control text-lg-end col-3" formControlName="weeklyMax" id="max-per-week"
                      placeholder="Enter a number" [min]="0" [max]="20">
               <div *ngIf="form.get('weeklyMax').invalid && (form.get('weeklyMax').dirty || form.get('weeklyMax').touched)">
                 <div *ngIf="form.get('weeklyMax').errors['pattern']" class="input-error">Only integer numbers are allowed</div>
                 <div *ngIf="form.get('weeklyMax').errors['min'] || form.get('weeklyMax').errors['max']"
                      class="input-error">Only numbers between 0 and 20 allowed</div>
                 <div *ngIf="form.get('weeklyMax').errors['invalidValue']" class="input-error">Week limit shouldn't be less than day limit</div>
               </div>
               <div class="text-muted field-hint">
                 0 not assign interview. Leave empty for no limits. Max is 20
               </div>
             </div>
           </div>

            <div class="mt-4 col-md-8">
              <div class="button">
                <button [disabled]="inProgress" [disabled]="this.form.invalid"  class="btn custom-btn" type="submit">
                  <span *ngIf="inProgress" class="spinner-border sr-only"></span>&nbsp;Set limits
                </button>
              </div>
            </div>

          </div>
        </form>
      </div>
      <div class="col-md-6">
        <div class="p-t-20">
          <p>The limitations only work on a DXC calendar. If you don’t have actual DXC calendar, (e.g. you track your meetings in the client’ calendar) then submit your availability using this <a style="cursor: pointer; color:#004180;text-decoration:none;" (click)="downloadInstruction()">instruction.</a><mat-icon style="cursor: pointer; color:#004180" (click)="downloadInstruction()" class="download">file_download</mat-icon></p>
        </div>
      </div>
    </div>
  </div>
</div>
